<template>
  <div id="layout">
    <el-container>
      <el-header style="height:105px;">
        <Header></Header>
      </el-header>
      <div class="el-main-col">
        <transition name="fade" mode="out-in">
          <keep-alive :include="cachedViews" >
            <router-view />
          </keep-alive>
        </transition>
      </div>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
     cachedViews : ['FormulaHomeList']
    }
  },
}
</script>
<style scoped lang="scss">
#layout {
  width: 100%;
  height: 100%;
}
.el-container {
  height: 100%;
}

.el-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url("../assets/images/banner-bg1.jpg");
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  width: 100%;
}

.el-header,
.el-footer {
  background-color: #fff;
  color: #333;
  text-align: center;
  padding: inherit;
}

.el-aside {
  background-color: #545c64;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-menu {
  width: 240px;
  border-right: none;
}

.el-main-col {
  background-color: #0096880f;
  color: #333;
  text-align: center;
  padding: inherit;
  margin-top: 105px;
}

.tag-box {
  text-align: left;
  margin-bottom: 10px;
  .el-tag {
    margin-right: 4px;
    cursor: pointer;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>