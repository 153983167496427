let websockets;
let isconncet = false;
//创建一个数组对象用于存放当前的连接的状态，以便在页面上实时展示出来当前的状态
let statusArr = [
    { state: 0, value: '正在连接' },
    { state: 1, value: '已建立连接' },
    { state: 2, value: '正在关闭连接' },
    { state: 3, value: '已关闭连接' },
]
/**
*   建立连接
*
*/
export function OcrRecognition(callBack) {
    // if (!isconncet) {
        // 1. 创建websockets对象，参数为服务器websockets地址
        let hostname = location.hostname;
        var url = "ws:127.0.0.1:9001"
        websockets = new WebSocket(url);

        // 2.监听websocket的状态变化，接收的信息，关闭时的状态

        //监听连接状态的变化
        websockets.onopen = (event) => {
            socketChange(callBack);
        }

        //监听关闭时的状态变化
        websockets.onclose = (event) => socketChange(callBack);

    // }
    // else {
    //     closeConnect();
    //     callBack('失败3')
    // }
}
function TimeOutReadIDCard() {
    if (!isconncet) {
        document.getElementById("partyLog").value = "未连接服务";
    } else {
        clear();
        let paramTimeOut = document.querySelector("#timeOut").value;
        paramTimeOut = "timeout=" + paramTimeOut;
        var parameterAll = paramTimeOut;
        let val = "02?" + parameterAll;
        websockets.send(val);
    }
}
export function ReadIDCard(callBack) {
    // if (!isconncet) {
    //     callBack({})
    // } else {
        var result = {
            name: '',
            idNumber: '',
            effDate: '',
            expDate: '',
            readerCode: '',
        }
        var paramTimeOut = 10000
        var time = 0
        // let val = "03?timeout=" + paramTimeOut;
        websockets.send("03?timeout=" + paramTimeOut);
        websockets.send("05?timeout=" + paramTimeOut);
        //监听接收消息的情况
        websockets.onmessage = (res) => {
            time += 1
            if (res.data == "failed to obtain ID card information") {
                return;
            }
            var alldata = res.data.split("|");
            // console.log(alldata);
            if (alldata.length >= 17) {
                result.name = alldata[3]
                result.idNumber = alldata[8]
                result.effDate = alldata[10]
                result.expDate = alldata[11]
            }
            else {
                result.readerCode = alldata[0].split('-').slice(0,3).join('-')
            }
            let _empty = Object.values(result).filter(t => {
                return t == ''
            })
            // console.log(result);
            if(_empty.length == 0) callBack(result)
            if(time == 2 && _empty.length != 0) {
                callBack(result)
            }
        }
    // }
}
function ReadIDCardNoJudge() {
    if (!isconncet) {
        document.getElementById("partyLog").value = "未连接服务";
    } else {
        clear();
        let paramTimeOut = document.querySelector("#timeOut").value;
        paramTimeOut = "timeout=" + paramTimeOut;
        var parameterAll = paramTimeOut;
        let val = "04?" + parameterAll;
        websockets.send(val);
    }
}
/**
*   socket状态变化
*
*/
function socketChange(callBack) {
    let state = websockets.readyState;
    let val = statusArr.map((item) => {
        if (item.state == state) {
            return item.value
        }
    });


    //实时显示状态的变化
    if (state == 1) {
        callBack('华视')
    }
    //读身份证
    if (1 == state) {
        isconncet = true;
        // document.getElementById("OcrRecognition").innerHTML = "断开服务";
    }
    if (3 == state) {
        isconncet = false;
        callBack('失败3')
    }


}
/**
*   关闭连接
*
*/
function closeConnect() {
    websockets.close();
}