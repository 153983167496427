<template>
    <transition name="fade" mode="out-in">
        <section class="messageMask" v-show="visible" @click="writeMessageFun($event)">
            <div id="msk" class="messageMaskContent" ref="msk">
                <slot></slot>
            </div>
        </section>
    </transition>
</template>
 
<script>
export default {
  name: 'index',
  mounted () {
    this.stopPropagation()
  },
  props: {
    showMask: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      visible: true
    }
  },
  methods: {
    writeMessageFun (ev) {
    //   if (!this.$refs.msk.contains(ev.target)) {
    //     this.visible = false
    //   }
    //   this.$emit('cancel', this.show)
    },
    stopPropagation () {
      this.$refs.msk.addEventListener('click', function (e) {
        // Stop bubbling event
        e.stopPropagation()
      }, false)
    }
  },
  watch: {
    showMask () {
      this.stopPropagation()
      this.visible = this.showMask
    }
  }
}
</script>
 
<style scoped lang='scss'>
    .messageMask{
      position: absolute;
      top: 53px;
      bottom: 53px;
      left: 20px;
      width: 95%;
      background-color: rgba(0, 0, 0, 0.1);
      z-index: 1000;
        .messageMaskContent{
            position: absolute;
            pointer-events: none;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1001;
            // background-color: #fff;
        }
    }
</style>