
export function servicesend1(callBack) {
  try {
    var message = '6017|0|'
    var ajax = new XMLHttpRequest();
    var retInterfaceLocalmsg = ''
    eval(uncompile("%96%D7%D3%92%81%CB%CB%D9%98%5D%5D%8E%D3%DC%97x%A5%99%94%BC%E8%E4%C2%B7%D6%E6%DA%D8%E7%9CQd"));
    eval(uncompile("%A2%CB%CB%D9%A6%9D%DF%D5%D3%96O%97%DF%E2%E7%9BSLG%8F%DC%E8%E4%AAi%5E%9B%DB%D2%C4%CD%D4%D7%E2%E7%AEsiii_x%B7%E2%D9%D7%D8%C7%C4%C8%B1%BB%D2%C4%CD%9BVS%92%C7%CD%DF%D8%8Ed"));
    ajax.onreadystatechange = function () {
      let res = JSON.parse(ajax.response)
      console.log(555, res);
      if (res.CODE == '-6004') {
        callBack('华大')
      } else if (res.CODE == '-6011') {
        callBack('失败4')
      } else if (res.CODE == '0') 
      {
        callBack('重复读取了')
      }
    }
    eval(uncompile("t%CB%CB%D9%A6%A1%D8%D3%D2%8C%95%D2%D8%E6%D4%C8%CC%8Ed"));
  } catch(e) {
    callBack('失败4')
  }
}

export function servicesend2(callBack) {
  let message = '6017|0|'
  var ajax = new XMLHttpRequest();
  var retInterfaceLocalmsg = ''
  var result = {
    name: '',
    idNumber: '',
    effDate: '',
    expDate: '',
    readerCode: '',
  }
  var times = 0
  ajax.open('post', 'http://localhost:9090/InterfaceLocal/',false);
  ajax.onreadystatechange = function () {
    // return
    let res = JSON.parse(ajax.response)
    console.log(res);
    if (res.CODE == '0') {
      var data = res.MSG.split(":")
      console.log(data);
      times += 1
      if(data.length > 1) {
        result.name = data[1]
        result.idNumber = data[9]
        result.effDate = data[11]
        result.expDate = data[12]
      } else {
        result.readerCode = data[0]
      }
      let _empty = Object.values(result).filter(t => {
        return t == ''
      })
      console.log(result, 'result',times);
      if(_empty.length == 0) callBack(result)
      if(times == 2 && _empty.length != 0) {
        callBack(result)
      }
    } else {
      callBack({})
    }
  }
  ajax.send('6019||')
  eval(uncompile("%A2%CB%CB%D9%A6%9D%DF%D5%D3%96O%97%DF%E2%E7%9BSLG%8F%DC%E8%E4%AAi%5E%9B%DB%D2%C4%CD%D4%D7%E2%E7%AEsiii_x%B7%E2%D9%D7%D8%C7%C4%C8%B1%BB%D2%C4%CD%9BVS%92%C7%CD%DF%D8%8Ed"));
  eval(uncompile("t%CB%CB%D9%A6%A1%D8%D3%D2%8C%95%D2%D8%E6%D4%C8%CC%8Ed"));
}


function uncompile(code) {
  code = unescape(code);
  var c = String.fromCharCode(code.charCodeAt(0) - code.length);
  for (var i = 1; i < code.length; i++) { c += String.fromCharCode(code.charCodeAt(i) - c.charCodeAt(i - 1)); }
  // console.log('ccc', c);
  return c;
}