<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="isShowAtypism"
      append-to-body
      width="70%"
      class="my-dialog-atypism"
      center
    >
      <template slot="title">
        <div class="dialog-header">材料</div>
      </template>
      <div v-loading="isLoading">
        <div class="dely" style="">
          <div
            class="btn btn-update btn-update-blur"
            @click="handleSignature()"
          >
            签 章
          </div>
        </div>
        <div class="iframe-box">
          <div class="iframe-box-in">
            <iframe class="iframe-box-in" :src="pdfSrc"></iframe>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowAtypism = false">关闭</el-button>
        <el-button type="primary" @click="handleContinueSubmit()"
          >确认</el-button
        >
      </span>
    </el-dialog>
    <Qianzhang
      ref="googleSelecter"
      @onDialogClose="googleSelecterClose"
    ></Qianzhang>
  </div>
</template>

<script>
import { uploadFileBase64New  } from "@/api/formulaHomeApi";
export default {
  data() {
    return {
      isShowAtypism: false,
      isLoading: false,
      pdfSrc: "",
      pdfFileIds: "",
      pdfInfo: null,
    };
  },
  methods: {
    // 清空
    doReset() {
      this.pdfSrc = "";
      this.pdfFileIds = "";
      this.isShowAtypism = true;
    },
    // 保存
    async handleContinueSubmit() {
      console.log("保存");
      if (!this.pdfFileIds) {
        this.$message({
          message: "请先签章！",
          type: "error",
        });
        return;
      }
      this.isShowAtypism = false;
      this.$emit('successSavePdf',this.pdfFileIds)
    },
    // 获取数字证照
    async getPdf(url) {
      
      this.doReset();
      this.pdfSrc = url;
    },
    // 签章
    handleSignature() {
      if (!this.pdfSrc) {
        this.$message({
          message: "请先生成数字证照！",
          type: "error",
        });
        return;
      }
      const encode = window.btoa(this.pdfSrc);
      const btoaUrl = window.location.origin + "/";
      const urlEncode = window.btoa(btoaUrl);
      const url = `https://qzpublic.lnwlzb.com/signature/H5QZ.htm?fileURL=${encode}&back=${urlEncode}`;
      const obj = { url };
      this.$refs.googleSelecter.findDownlodaList(true, obj, true);
    },
    // 签章完成 并上传
    googleSelecterClose(res) {
      res = res.split(",");
      this.handleUploadFilePdf(res[1]);
    },
    // 数字证照签章
    async handleUploadFilePdf(base64) {
      const { code:listCode } = this.$route.query
      const ocrDataPdf = {
        signatureStatus: 1, // 1 有 0 没有签章
        code:listCode,
      };
      ocrDataPdf.fileBase64 = base64;
      const { data, code } = await uploadFileBase64New(ocrDataPdf);
      if (code == 200) {
        this.$alert("签章成功!", "", { confirmButtonText: "确定" });
        this.pdfFileIds = data.rowId;
        this.pdfSrc = data.attachmentUrl;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dely {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  font-size: 18px;
  justify-content: right;
  .btn {
    vertical-align: top;
    margin: 15px;
    text-align: center;
    width: 122px;
    height: 44px;
    line-height: 44px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    float: right;
  }
  .btn-update {
    vertical-align: top;
    height: 42px;
    line-height: 42px;
    width: 120px;
    transition: all 0.2s ease-in-out;
    position: relative;
    opacity: 1;
    overflow: hidden;
    height: 33px;
    line-height: 33px;
    background: #ffba00ee;
    transition: all 0.5s;
    box-shadow: 0px 5px 0px 0px #e6a700;
  }
  .btn-update-blur {
    background: #55acee;
    transition: all 0.5s;
    box-shadow: 0px 5px 0px 0px #3486d5;
  }

  .btn-update:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }

  .btn-update:hover {
    color: #fff;
  }

  .btn-update:hover:before {
    transform: skewX(-45deg) translateX(260px);
    transition: all 0.5s ease-in-out;
  }

  .btn-update:active {
    opacity: 0.8;
    transform: translate(0px, 4px);
    -webkit-transform: translate(0px, 4px);
    box-shadow: 0px 1px 0px 0px #e6a700;
  }
  .btn-update-blur:active {
    opacity: 0.8;
    transform: translate(0px, 4px);
    -webkit-transform: translate(0px, 4px);
    box-shadow: 0px 1px 0px 0px #3486d5;
  }
}
.dialog-header {
  padding: 18px 0px;
  text-align: center;
  font-size: 26px;
  // font-weight: 700;
  // background: #3486d5;
}
.text-tip {
  font-size: 18px;
}
.iframe-box {
  width: 100%;
  height: 600px;
  padding: 20px 0;
  .iframe-box-in {
    width: 100%;
    height: 100%;
  }
}
</style>
<style scoped>
.my-dialog-atypism >>> .el-dialog__header {
  padding: inherit;
}
.my-dialog-atypism >>>  .el-dialog__body {
    text-align: initial;
    padding: 0px 25px 30px;
}
</style>