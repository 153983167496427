<template>
  <div :class="{'hidden':hidden}" class="pagination-container">
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      hide-on-single-page
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { scrollTo } from '@/utils/scroll-to'

export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50, 200, 1000]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', { page: this.currentPage, limit: val })
      if (this.autoScroll) {
        scrollTo(300, 200)
      }
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
      if (this.autoScroll) {
        scrollTo(300, 200)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  // background: #fff;
  padding: 32px 16px;
}
.pagination-container.hidden {
  display: none;
}

/*::v-deep{*/
/*  .el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next, .el-pagination.is-background .el-pager li{*/
/*    background-color: #fff;   // 进行修改未选中背景和字体*/
/*    border: 1px #295FCB solid;*/
/*  }*/
/*  .el-pagination.is-background .el-pager li:not(.disabled) {*/
/*    background-color: #fff;   // 进行修改未选中背景和字体*/
/*    border: 1px #295FCB solid;*/
/*  }*/
/*  .el-pagination.is-background .el-pager li:not(.disabled).active{*/
/*    background-color: #295FCB;*/
/*  }*/
/*  .el-select-dropdown el-popper{*/
/*    color: #295FCB;*/
/*  }*/
/*}*/
</style>
