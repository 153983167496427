<template>
  <div id="header">
    <div class="content">
      <div class="content-left">
        <img
          class="my-img"
          src="../../assets/images/banner.png"
          alt=""
          @click="handleBack"
        />
        <!-- <div class="header-text">
            辽宁省工程建设信息明盒公示系统
        </div> -->
      </div>
      <!-- <div class="content-right">
        <img class="my-img my-img-phone" src="../../assets/images/phone.png" alt="">
        <div class=" header-text header-text-right">
           服务电话： 19802479955
        </div>
        
      </div> -->
      <div class="login-header-item-phone content-right">
        <el-popover
          placement="top-start"
          width="400"
          popper-class="popperClass"
          trigger="hover"
        >
          <!-- <div class="des-row row-title" style="color: black">
            辽宁省工程建设信息明盒公示系统
          </div> -->
          <div class="des-row">
            联系电话: <span style="margin-left: 20px"></span> 19802479955
            <span style="margin-left: 20px"></span> 13644976603
          </div>
          <div class="des-row">
            办公时间： 周一至周五  08:30 - 11:30(工作日)
          </div>
          <div style="margin-left: 37%;">
                                 13:00 - 17:00(工作日)
          </div>
          <div slot="reference">
            <img
              src="~@/assets/images/kefu.png"
              class="login-header-logo"
            />联系我们
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleBack() {
      this.$router.push({
        path: "/formulaHome",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#header {
  width: 1600px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  .content {
    width: 100%;
    height: 100%;
    .content-left,
    .content-right {
      float: left;
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;

      .my-img {
        width: 560px;
        // height: 60px;
        align-items: center;
        cursor: pointer;
      }
      .my-img-phone {
        width: 25px;
        height: 25px;
      }
      .header-text {
        align-self: center;
        color: #fff;
        margin-left: 15px;
        font-size: 20px;
        font-weight: 600;
      }
      .header-text-right {
        margin-right: 15px;
        color: #fff;
        font-weight: 700;
      }
    }
    .content-right {
      float: right;
      width: 47%;
      height: 100%;
      //  background: #9e2b2b;
      justify-content: end;
    }
  }
}
.login-header-item-phone {
  position: relative;
  top: -4px;
  cursor: pointer;
  border-radius: 16px;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  line-height: 25px;
  margin-right: 15px;
  &.hover-effect {
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  img {
    height: 32px;
    width: 32px;
    position: relative;
    top: 9px;
  }
}

.popperClass {
  font-size: 20px;
  font-weight: 600;
  padding: 12px 20px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
  .des-row {
    display: flex;
    margin: 10px 0;
    .worktime-split {
      margin: 0 8px;
      position: relative;
      top: -2px;
    }
  }
  .row-title {
    color: black;
  }
}
</style>