/**
 * 格式化日期时间字符串
 *
 * npm install moment --save
 *
 * 使用示例：
 *
 * 1、引用：
 * import { dateFormatYMDHMS } from '@/utils/formatter'
 *
 * 2、在方法中调用
 * dateFormat(row,column) {
     return dateFormatYMDHMS(row, column)
   },
 *
 * 3、在table中格式化显示字符串
 * <el-table-column
    prop="docGetStartTime"
    width="180"
    align="center"
    :formatter="dateFormat"
    >
  </el-table-column>

 */

import moment from 'moment'

/**
 * 显示 年-月-日 时:分:秒
 * @param {Object} row
 * @param {Object} column
 */
export function dateFormatYMDHMS(row, column) {
  var date = row[column.property]
  // eslint-disable-next-line eqeqeq
  if (date == undefined) { return '' }
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

/**
 * 显示 年-月-日
 * @param {Object} row
 * @param {Object} column
 */
export function dateFormatYMD(row, column) {
  var date = row[column.property]
  // eslint-disable-next-line eqeqeq
  if (date == undefined) { return '' }
  return moment(date).format('YYYY-MM-DD')
}

/**
 * 显示 年-月-日
 * @param {Object} row
 * @param {Object} column
 */
export function dateFormatYYMMDD(val) {
  if (val === undefined) { return '' }
  return moment(val).format('YYYY-MM-DD')
}

/**
 * 显示 年-月
 * @param {Object} row
 * @param {Object} column
 */
export function dateFormatYM(row, column) {
  var date = row[column.property]
  // eslint-disable-next-line eqeqeq
  if (date == undefined) { return '' }
  return moment(date).format('YYYY-MM')
}

/**
 * 显示 年-月-日
 * @param {Object} row
 * @param {Object} column
 */
export function dateFormatYMDHM(row, column) {
  var date = row[column.property]
  // eslint-disable-next-line eqeqeq
  if (date == undefined) { return '' }
  return moment(date).format('YYYY-MM-DD HH:mm')
}

export function durationArray(timeArray) {
  var firstTime = moment(timeArray[0]).format('x')
  var lastTime = moment(timeArray[1]).format('x')
  return (lastTime - firstTime) / 1000 / 60 / 60 / 24
}

export function duration(first, last) {
  var firstTime = moment(first).format('x')
  var lastTime = moment(last).format('x')
  return Math.round((lastTime - firstTime) / 1000 / 60 / 60 / 24)
}

export function FormatYM(date) {
  // eslint-disable-next-line eqeqeq
  if (date == undefined) { return '' }
  return moment(date).format('YYYY-MM')
}

export function FormatYMD(date) {
  // eslint-disable-next-line eqeqeq
  if (date == undefined) { return '' }
  return moment(date).format('YYYY-MM-DD')
}

export function FormatYMDHM(date) {
  // eslint-disable-next-line eqeqeq
  if (date == undefined) { return '' }
  return moment(date).format('YYYY-MM-DD HH:mm')
}

export function FormatYMDHMS(date) {
  // eslint-disable-next-line eqeqeq
  if (date == undefined) { return '' }
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

