import router from './router'
import { getToken } from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'
/* eslint-disable */
const whiteList = ['/login']
router.beforeEach(async (to, from, next) => {
  document.title = getPageTitle(to.meta.title)
  const hasToken = true  // getToken()
  if (hasToken) {
    if (to.path === '/login') {
      next()
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/login')
    }
  }
})

router.afterEach(() => {})
