import { connect, readCert } from "@/utils/IDread/JLread";
import { StartWebSocket, IdReadCard } from "@/utils/IDread/SSread";
import { OcrRecognition, ReadIDCard } from "@/utils/IDread/HSread";
import { forConnect, myDo1,servicesend1,servicesend2 } from "@/utils/IDread/HDread";
// import { checkItemNo } from '@/api/qualificationMenu'
export default {
  data() {
    return {
      result: "",
      type: "",
      user: {},
      isJl: false,
      isSs: false,
      isHs: false,
      isHd: false,
      isC:false,
      idCardType: 1
      // connectFalse:false
    };
  },
  mounted() {
    // this.toConnect();
  },
  computed: {
    // 判断是否连接上了
    hasConnect() {
          console.log(88889,this.isJl , this.isSs , this.isHs , this.isHd);
          // 全为false是初始状态，全为true是全失败状态
      if (this.isJl && this.isSs && this.isHs && this.isHd || !this.isJl && !this.isSs && !this.isHs && !this.isHd) {
      // if (this.isJl && this.isSs && this.isHs  || !this.isJl && !this.isSs && !this.isHs ) {
        console.log(false);
        return false;
      } else {
        console.log(true);
        return true;
      }
    },
  },
  watch: {
    type: {
      handler(val) {
        console.log("变化了", val);
        if (val != "" && val != null) {
          // 滚动判断什么读卡器未连接上
          switch (val) {
            case "失败1":
              this.isJl = true;
              break;
            case "失败2":
              this.isSs = true;
              break;
            case "失败3":
              this.isHs = true;
              break;
            case "失败4":
              this.isHd = true;
              break;
          }
          console.log(8989,this.isJl , this.isSs , this.isHs , this.isHd);
          // 当读卡器都没有连接成功时弹窗
          if (this.isJl && this.isSs && this.isHs && this.isHd) {
            this.user = {}
            if(this.idCardType == 1) {
              this.$alert("未连接读卡器", "提示", {
                confirmButtonText: "确定",
                showClose: false,
                callback: action => {
                  this.failedRead()
                }
              });
            } else {
              this.$alert("IC卡读取不成功，请重新尝试。", "提示", {
                confirmButtonText: "确定",
                showClose: false,
                callback: action => {
                  this.failedRead()
                }
              });
            }
            // this.connectFalse = true
          }
          // 按下按钮并且没有返回失败后才调用
          if (this.isC&&val.lastIndexOf("失败") == -1) {
            this.IdRead();
          }

          // 华大会有重复读取状态
          if (this.isC&&val.lastIndexOf("重复读取了") !== -1) {
            this.HdReadID()
          }
        }
      },
    },
  },
  methods: {
    // 读卡
    IdRead() {
      // 判断是什么类型的读卡器连接成功了
      console.log('this.type',this.type);
      switch (this.type) {
        case "精伦":
          this.toReadCert();
          break;
        case "神思":
          this.IdReadCard();
          break;
        case "华视":
          this.onOcrRecognition();
          break;
        case "华大":
          this.HdReadID();
          break;
      }
    },
    // 清空数据
    clearData() {
      this.isJl = false;
      this.isSs = false;
      this.isHs = false;
      this.isHd = false;
      // this.connectFalse = false
    },
    // 按下连接
    isConnect() {
      // isC  当按下读取后才开始读卡
      this.isC = true
      // 当是精伦（第一个）并没有调watch
      if (this.hasConnect || (this.hasConnect&&this.type == '精伦')) {
        this.IdRead();
      } else {
        this.toConnect();
      }
    },
    // 连接
    toConnect(type = 1) {
      this.idCardType = type // 1 身份证  2Ic卡
      this.clearData();
      let result = connect();
      console.log("结果:", result);
      this.type = result.type;
      if (this.type.lastIndexOf("失败") !== -1) {
        this.onStartWebSocket();
      }else{
        this.toReadCert()
      }
    },
    // 精伦读卡
    toReadCert() {
      let data = readCert();
      console.log("jinglun :", data);
      if(data.readerCode) {
        if(!data.idNumber) {
          if(this.idCardType == 1) {
            this.$alert("未读取到人员身份信息", "提示", {
              confirmButtonText: "确定",
              showClose: false,
              callback: action => {
                this.failedRead()
              }
            });
          } else {
            this.$alert("IC卡读取不成功，请重新尝试。", "提示", {
              confirmButtonText: "确定",
              showClose: false,
              callback: action => {
                this.failedRead()
              }
            });
          }
          return
        }
        const { idNumber , name } = data
        let info ={
          name,
          idCard:idNumber
        }
        this.readSuccess(info)
        this.$alert("读取成功！", "提示", {
          confirmButtonText: "确定",
          showClose: false,
        });
      } else {
        this.$alert("未连接读卡器", "提示", {
          confirmButtonText: "确定",
          showClose: false,
          callback: action => {
            this.failedRead()
          }
        });
      }
    },
    // 神思连接
    onStartWebSocket() {
      StartWebSocket(this.statusFun);
      console.log("神思");
    },
    // 神思连接返回值
    statusFun(type) {
      this.type = type;
      if (this.type.lastIndexOf("失败") !== -1) {
        this.HsConnect();
      }else {
        this.IdReadCard();
      }
    },
    // 神思读卡
    IdReadCard() {
      IdReadCard(this.getInfo);
    },
    // 神思读卡数据
    getInfo(val) {
      console.log('shensi', val);
      if(val.readerCode) {
        if(!val.idNumber) {
          if(this.idCardType == 1) {
            this.$alert("未读取到人员身份信息", "提示", {
              confirmButtonText: "确定",
              showClose: false,
              callback: action => {
                this.failedRead()
              }
            });
          } else {
            this.$alert("IC卡读取不成功，请重新尝试。", "提示", {
              confirmButtonText: "确定",
              showClose: false,
              callback: action => {
                this.failedRead()
              }
            });
          }
          return
        }
        const { idNumber , name } = val
        let info ={
          name,
          idCard:idNumber
        }
        this.readSuccess(info)
        this.$alert("读取成功！", "提示", {
          confirmButtonText: "确定",
          showClose: false,
        });
      } else {
        this.$alert("未连接读卡器", "提示", {
          confirmButtonText: "确定",
          showClose: false,
          callback: action => {
            this.failedRead()
          }
        });
      }
    },
    // 华视连接
    HsConnect() {
      OcrRecognition(this.HsStatus);
    },
    // 华视连接返回值
    HsStatus(type) {
      console.log("华视", type);
      this.type = type;
      console.log(55555,this.type);
      if (this.type.lastIndexOf("失败") !== -1) {
      this.isHs = true;
        // this.result = '端口连接失败！'
        this.HdConnect();
      }else {
      this.isHs = false;

        this.onOcrRecognition();
      }
    },
    // 华视读卡
    onOcrRecognition() {
      ReadIDCard(this.HsInfo);
    },
    // 华视读卡数据
    HsInfo(val) {
      console.log("huashi", val);
      if(val.readerCode && val.readerCode != '连接设备失败') {
        if(!val.idNumber) {
          if(this.idCardType == 1) {
            this.$alert("未读取到人员身份信息", "提示", {
              confirmButtonText: "确定",
              showClose: false,
              callback: action => {
                this.failedRead()
              }
            });
          } else {
            this.$alert("IC卡读取不成功，请重新尝试。", "提示", {
              confirmButtonText: "确定",
              showClose: false,
              callback: action => {
                this.failedRead()
              }
            });
          }
          return
        }
        const { idNumber , name } = val
        let info ={
          name,
          idCard:idNumber
        }
        this.readSuccess(info)
        this.$alert("读取成功！", "提示", {
          confirmButtonText: "确定",
          showClose: false,
        });
      } else {
        this.$alert("未连接读卡器", "提示", {
          confirmButtonText: "确定",
          showClose: false,
          callback: action => {
            this.failedRead()
          }
        });
      }
    },
    // 华大连接
    HdConnect() {
      servicesend1(this.HdStatus);
    },
    // 华大连接返回值
    HdStatus(type) {
      this.type = type;
      // console.log("华大", this.type);
      if (this.type.lastIndexOf("失败") == -1) {
        // this.result = '端口连接失败！'
        this.user = {}
        this.HdReadID();
      }
    },
    // 华大读卡
    HdReadID() {
      servicesend2(this.HdInfo);
    },
    // 华大读卡数据
    HdInfo(val) {
      console.log("华大", val);
      if(val.readerCode) {
        if(!val.idNumber) {
          if(this.idCardType == 1) {
            this.$alert("未读取到人员身份信息", "提示", {
              confirmButtonText: "确定",
              showClose: false,
              callback: action => {
                this.failedRead()
              }
            });
          } else {
            this.$alert("IC卡读取不成功，请重新尝试。", "提示", {
              confirmButtonText: "确定",
              showClose: false,
              callback: action => {
                this.failedRead()
              }
            });
          }
          return
        }
        const { idNumber , name } = val
        let info ={
          name,
          idCard:idNumber
        }
        this.readSuccess(info)
        this.$alert("读取成功！", "提示", {
          confirmButtonText: "确定",
          showClose: false,
        });
      } else {
        this.$alert("未连接读卡器", "提示", {
          confirmButtonText: "确定",
          showClose: false,
          callback: action => {
            this.failedRead()
          }
        });
      }
    },
    // 传值
    transformDate() {
      if (JSON.stringify(this.user) === "{}") {
        this.$alert("未连接读卡器", "提示", {
          confirmButtonText: "确定",
          showClose: false,
          callback: action => {
            this.failedRead()
          }
        });
        this.$emit("IdInfo", this.user);
      } else {
        this.$emit("IdInfo", this.user);
      }
    },
    failedRead() {
    },
  },
};
