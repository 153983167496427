//创建读卡控件
var CertCtl = new IDCertCtl();

var timer = null;
//身份证读卡控件创建
function IDCertCtl() {
  //创建用于与服务交换数据的对象
  this.xhr = createXmlHttp();
  this.type = "CertCtl";
  this.height = 0;
  this.width = 0;
  //连接
  this.connect = CertCtl_connect;
  //断开
  this.disconnect = CertCtl_disconnect;
  //获取状态
  this.getStatus = CertCtl_getStatus;
  //读卡
  this.readCert = CertCtl_readCert;
  //读IC卡序列号
  this.readICCardSN = CertCtl_readICCardSN;
  //读身份证物理卡号
  this.readIDCardSN = CertCtl_readIDCardSN;
  //读安全模块接口
  this.getSAMID = CertCtl_getSAMID;
  //读M1卡扇区内容
  this.readM1Card = CertCtl_readM1Card;
  //写M1卡扇区内容
  this.writeM1Card = CertCtl_writeM1Card;
  //处理CPU卡APDU指令
  this.handleAPDUCMD = CertCtl_handleAPDUCMD;
  //设置项目识别码
  //this.setProgramCode = CertCtl_setProgramCode;
  //核验项目识别码
  this.checkProgramCode = CertCtl_checkProgramCode;
  this.Routon_Mute = CertCtl_RoutonMute;
  this.setRepeatRead = CertCtl_setRepeatRead;
  //循环读卡
  this.cycleRead = CertCtl_cycleRead;
}

//创建XMLHttpRequest 对象，用于在后台与服务器交换数据
function createXmlHttp() {
  var xmlHttp = null;
  //根据window.XMLHttpRequest对象是否存在使用不同的创建方式
  if (window.XMLHttpRequest) {
    xmlHttp = new XMLHttpRequest();                  //FireFox、Opera等浏览器支持的创建方式
  }
  else if (window.ActiveXObject) {
    xmlHttp = new ActiveXObject("Microsoft.XMLHTTP");//IE浏览器支持的创建方式
  }
  xmlHttp.withCredentials = false;
  return xmlHttp;
}

//连接方法
function CertCtl_connect() {
  var result = "";
  var iParam1 = "1";
  //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
  CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/connect", false);
  //发送请求
  try {
    CertCtl.xhr.send();
  } catch (e) {
  }
  //返回值readyState   0: 请求未初始化
  //				    1: 服务器连接已建立
  //				    2：请求已接收
  //				    3: 请求处理中
  //				    4: 请求已完成，且响应已就绪
  //返回值status      200: "OK"
  //					404: 未找到页面
  //当返回值readyState为4且status为200时,为查询成功
  if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
    result = CertCtl.xhr.responseText;
    console.log(222, result);
    // CertCtl.xhr.readyState = 1;
  }
  return result;
}

//断开方法
function CertCtl_disconnect() {
  var result = "";
  //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
  CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/disconnect", false);
  //发送请求
  try {
    CertCtl.xhr.send();
  } catch (e) {
  }
  if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
    result = CertCtl.xhr.responseText;
    CertCtl.xhr.readyState = 1;
  }
  return result;
}
//获取状态方法
function CertCtl_getStatus() {
  var result = "";
  //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
  CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/getStatus", false);
  //发送请求
  try {
    CertCtl.xhr.send();
  } catch (e) {
  }
  if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
    result = CertCtl.xhr.responseText;
    CertCtl.xhr.readyState = 1;
  }
  return result;
}

//执行读卡操作
function CertCtl_readCert() {
  var result = "";
  try {
    //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
    CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/readCard", false); //readCard读卡，不生成正反面仿复印件
    //CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/readCert", false);	  //readCert读卡，生成正反面仿复印件	
    //发送请求
    CertCtl.xhr.send();
    if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
      result = CertCtl.xhr.responseText;
      CertCtl.xhr.readyState = 1;
    }
  } catch (e) {

  }
  return result;
}

//执行循环读卡操作
function CertCtl_cycleRead() {
  var result = "";
  try {
    //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
    CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/readCard", false); //readCard读卡，不生成正反面仿复印件
    //CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/readCert", false);	  //readCert读卡，生成正反面仿复印件	
    //发送请求
    CertCtl.xhr.send();
    if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
      result = {
        name: CertCtl.xhr.responseText.resultContent.partyName,
        idNumber: CertCtl.xhr.responseText.resultContent.certNumber
      }
      CertCtl.xhr.readyState = 1;
    }
  } catch (e) {

  }
  return result;
}

//获取IC卡序列号
function CertCtl_readICCardSN() {
  var result = "";
  //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
  CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/readICCardSN", false);
  //发送请求
  try {
    CertCtl.xhr.send();
  } catch (e) {
  }
  if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
    result = CertCtl.xhr.responseText;
    CertCtl.xhr.readyState = 1;
  }
  return result;
}

//获取身份证物理卡号
function CertCtl_readIDCardSN() {
  var result = "";
  //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
  CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/readIDCardSN", false);
  //发送请求
  try {
    CertCtl.xhr.send();
  } catch (e) {
  }
  if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
    result = CertCtl.xhr.responseText;
    CertCtl.xhr.readyState = 1;
  }
  return result;
}

//获取SAMID
function CertCtl_getSAMID() {
  var result = "";
  //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
  //getSAMID 获取的SAMID，格式为：05032013102800015247360103645891
  //getSAMIDStr 获取SAMID，格式为：05.03-20131028-0001524736-0103645891
  CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/getSAMIDStr", false);
  //发送请求
  try {
    CertCtl.xhr.send();
  } catch (e) {
  }
  console.log(CertCtl.xhr);
  if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
    result = JSON.parse(CertCtl.xhr.responseText).SAMID;
    // CertCtl.xhr.readyState = 1;
  }
  return result;
}

//读M1卡扇区内容
function CertCtl_readM1Card() {
  var result = "";
  var sid = "1";
  var bid = "1";
  var keyType = "1";
  var key = "ffffffffffff";
  if (document.getElementById("input_sid").value != "") {
    sid = document.getElementById("input_sid").value;
  }

  if (document.getElementById("input_bid").value != "") {
    bid = document.getElementById("input_bid").value;
  }

  if (document.getElementById("input_keytype").value != "") {
    keyType = document.getElementById("input_keytype").value;
  }

  if (document.getElementById("input_key").value != "") {
    key = document.getElementById("input_key").value;
  }


  //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
  CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/readM1Card?" + "SID=" + sid + "&BID=" + bid + "&KEYTYPE=" + keyType + "&KEY=" + key, false);

  //发送请求
  try {
    CertCtl.xhr.send();
  } catch (e) {
  }
  if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
    result = CertCtl.xhr.responseText;
    CertCtl.xhr.readyState = 1;
  }
  return result;
}

//写M1卡扇区内容
function CertCtl_writeM1Card() {
  var result = "";
  var sid = "1";
  var bid = "1";
  var keyType = "1";
  var key = "ffffffffffff";
  var data = "00000000000000000000000000000000";
  if (document.getElementById("input_sid").value != "") {
    sid = document.getElementById("input_sid").value;
  }

  if (document.getElementById("input_bid").value != "") {
    bid = document.getElementById("input_bid").value;
  }

  if (document.getElementById("input_keytype").value != "") {
    keyType = document.getElementById("input_keytype").value;
  }

  if (document.getElementById("input_key").value != "") {
    key = document.getElementById("input_key").value;
  }

  if (document.getElementById("cmd").value != "") {
    data = document.getElementById("cmd").value;
  }
  //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
  CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/writeM1Card?" + "SID=" + sid + "&BID=" + bid + "&KEYTYPE=" + keyType + "&KEY=" + key + "&DATA=" + data, false);
  //CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/writeM1Card?"+"SID="+sid+"&BID="+bid+"&KEYTYPE="+keyType+"&KEY="+key+"&DATA="+data, false);
  //发送请求
  try {
    CertCtl.xhr.send();
  } catch (e) {
  }
  if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
    result = CertCtl.xhr.responseText;
    CertCtl.xhr.readyState = 1;
  }
  return result;
}

//处理CPU卡APDU指令
function CertCtl_handleAPDUCMD() {
  //var cmd = "00A4000000"; //基本命令（测试用）：选择MF下的根目录，返回值为：6F15840E315041592E5359532E4444463031A5038801019000
  var cmd = "";
  if (document.getElementById("cmd").value != "") {
    cmd = document.getElementById("cmd").value;
  }
  //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
  CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/handleAPDUCMD?" + "APDUCMD=" + cmd, false);
  //发送请求
  try {
    CertCtl.xhr.send();
  } catch (e) {
  }
  if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
    result = CertCtl.xhr.responseText;
    CertCtl.xhr.readyState = 1;
  }
  return result;
}

/*
//设置项目识别码
function CertCtl_setProgramCode(){
  var programData = "";
  if(document.getElementById("programData").value != "")
  {
    programData = document.getElementById("programData").value;
    
  }
  
  //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
        CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/setProgramCode?"+"PROGRAMCODE="+programData, false);
  //发送请求
        try {
          CertCtl.xhr.send();
        } catch (e) {
        }
        if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
            result = CertCtl.xhr.responseText;
            CertCtl.xhr.readyState = 1;
        }
        return result;
}
*/
//核验项目识别码
function CertCtl_checkProgramCode() {
  var programData = "";
  if (document.getElementById("programData").value != "") {
    programData = document.getElementById("programData").value;
  }
  //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
  CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/checkProgramCode?" + "PROGRAMCODE=" + programData, false);
  //发送请求
  try {
    CertCtl.xhr.send();
  } catch (e) {
  }
  if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
    result = CertCtl.xhr.responseText;
    CertCtl.xhr.readyState = 1;
  }
  return result;
}

//关闭声音
function CertCtl_RoutonMute() {
  var result = "";
  var IsMute = false; //false - 开声音（默认开）， true - 关声音
  //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
  CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/Routon_Mute?" + "ISMUTE=" + IsMute, false);
  //发送请求
  try {
    CertCtl.xhr.send();
  } catch (e) {
  }
  if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
    result = CertCtl.xhr.responseText;
    CertCtl.xhr.readyState = 1;
  }
  return result;
}

//设置重复读卡
function CertCtl_setRepeatRead() {
  var result = "";
  var IsRepeat = false; //false - 不重复（默认开）， true - 重复
  //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
  CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/setRepeatRead?" + "ISREPEAT=" + IsRepeat, false);
  //发送请求
  try {
    CertCtl.xhr.send();
  } catch (e) {
  }
  if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
    result = CertCtl.xhr.responseText;
    CertCtl.xhr.readyState = 1;
  }
  return result;
}
//转为Json格式
function toJson(str) {
  //var obj = JSON.parse(str);
  //return obj;
  return eval('(' + str + ')');
}

//连接方法
export function connect() {
  //清空页面
  // clearForm();

  try {
    //调用对应的连接方法,并赋值给result
    var result = CertCtl.connect();
    console.log(111, result);
    //如果result为空,代表读卡插件未启动
    if (result == "") {
      return {
        type: '失败1',
        result: '未启动读卡插件'
      }
    } else {
      //result页面回显
      // document.getElementById("result").value = result;
      if (result.lastIndexOf('"resultFlag":-1') != -1) {
        return {
          type: '失败1',
          result: '端口连接失败'
        }
      } else {
        return {
          type: '精伦',
          result: '端口连接成功'
        }
      }
    }
  } catch (e) {
    return 'error'
  }
}

//断开连接方法
function disconnect() {
  //清空页面
  clearForm(); 

  try {
    //调用对应的断开连接方法,并赋值给result
    var result = CertCtl.disconnect();
    //如果result为空,代表读卡插件未启动
    if (result == "") {
      return {
        type: '失败1',
        result: '未启动读卡插件'
      }
    } else {
      //result页面回显
      document.getElementById("result").value = result;
    }
  } catch (e) {
  }
}

//获取状态方法
function getStatus() {
  //清空页面
  clearForm();

  try {
    //调用对应的获取状态方法,并赋值给result
    var result = CertCtl.getStatus();
    //如果result为空,代表读卡插件未启动
    if (result == "") {
      alert("未启动读卡插件!")
    } else {
      //result页面回显
      document.getElementById("result").value = result;
    }
  } catch (e) {
  }
}

//读卡方法
export function readCert() {
  //清空页面
  // clearForm();
  //开始时间
  var startDt = new Date();
  //调用对应的读卡方法
  let _readerCode = CertCtl.getSAMID()
  var result = CertCtl.readCert();
  //如果result为空,代表读卡插件未启动
  if (result == "") {
    return '失败1'
  } else {
    var resultObj = eval('(' + result + ')');
    //resultFlag为0代表读卡成功
    if (resultObj.resultFlag == "0") {
      //回显相关数据
      // console.log(555555, resultObj,_readerCode);
      return {
        name: resultObj.resultContent.partyName,
        idNumber: resultObj.resultContent.certNumber,
        effDate:resultObj.resultContent.effDate,
        expDate:resultObj.resultContent.expDate,
        readerCode: _readerCode.split('-').slice(0,3).join('-')
      }
    } else if (resultObj.resultFlag == "-1") {
      if (resultObj.errorMsg == "端口打开失败") {
        return {};
      } else {
        return {readerCode: _readerCode.split('-').slice(0,3).join('-')};
      }
    } else if (resultObj.resultFlag == "-2") {
      return {};
    }
  }
}

function readICCardSN() {
  //清空页面
  clearForm();

  try {
    //调用对应的获取状态方法,并赋值给result
    //CertCtl.Routon_Mute();
    var result = CertCtl.readICCardSN();
    //如果result为空,代表读卡插件未启动
    if (result == "") {
      alert("未启动读卡插件!")
    } else {
      //result页面回显
      document.getElementById("result").value = result;
    }
  } catch (e) {
  }
}

function readIDCardSN() {
  //清空页面
  clearForm();

  try {
    //调用对应的获取状态方法,并赋值给result
    var result = CertCtl.readIDCardSN();
    //如果result为空,代表读卡插件未启动
    if (result == "") {
      alert("未启动读卡插件!")
    } else {
      //result页面回显
      document.getElementById("result").value = result;
    }
  } catch (e) {
  }
}

function getSAMID() {
  //清空页面
  clearForm();

  try {
    //调用对应的获取状态方法,并赋值给result
    var result = CertCtl.getSAMID();
    //如果result为空,代表读卡插件未启动
    if (result == "") {
      alert("未启动读卡插件!")
    } else {
      //result页面回显
      document.getElementById("result").value = result;
    }
  } catch (e) {
  }
}

function readM1Card() {
  //清空页面
  clearForm();

  try {
    //调用对应的获取状态方法,并赋值给result
    var result = CertCtl.readM1Card();
    //如果result为空,代表读卡插件未启动
    if (result == "") {
      alert("未启动读卡插件!")
    } else {
      //result页面回显
      document.getElementById("ACardResult").value = result;
    }
  } catch (e) {
  }
}

function writeM1Card() {
  //清空页面
  clearForm();

  try {
    //调用对应的获取状态方法,并赋值给result
    var result = CertCtl.writeM1Card();
    //如果result为空,代表读卡插件未启动
    if (result == "") {
      alert("未启动读卡插件!")
    } else {
      //result页面回显
      document.getElementById("ACardResult").value = result;
    }
  } catch (e) {
  }
}

function handleAPDUCMD() {
  //清空页面
  clearForm();

  try {
    //调用对应的获取状态方法,并赋值给result
    var result = CertCtl.handleAPDUCMD();
    //如果result为空,代表读卡插件未启动
    if (result == "") {
      alert("未启动读卡插件!")
    } else {
      //result页面回显
      document.getElementById("ACardResult").value = result;
    }
  } catch (e) {
  }
}

/*
function setProgramCode() {
  //清空页面
        clearForm();

        try {
    //调用对应的获取状态方法,并赋值给result
            var result = CertCtl.setProgramCode();
    //如果result为空,代表读卡插件未启动
            if (result == "") {
                alert("未启动读卡插件!")
            } else {
      //result页面回显
                document.getElementById("ACardResult").value = result;
            }
        } catch (e) {
        }
}
*/

/* 检测输入内容
function check()
{
  var programCode = document.getElementById("programData").value;
  if(programCode == "")
  {
    alert("项目码不能为空");
    return false;
  }
  else if(programCode.length != 8)
  {
    alert("项目码为8个字符");
    return false;
  }
  else 
  {
    for(var i=0; i<programCode.length; i++)
    {
      if(!(programCode.charCodeAt(i)>='0'.charCodeAt() && programCode.charCodeAt(i)<='9'.charCodeAt())
      && !(programCode.charCodeAt(i)>='a'.charCodeAt() && programCode.charCodeAt(i)<='z'.charCodeAt())
      && !(programCode.charCodeAt(i)>='A'.charCodeAt() && programCode.charCodeAt(i)<='Z'.charCodeAt())
      )
      {
        alert("项目码只能包含英文字母和数字");
        return false;
      }
    }
  }
  return true;
}
*/

function checkProgramCode() {
  //清空页面
  clearForm();

  try {
    //调用对应的获取状态方法,并赋值给result
    var result = CertCtl.checkProgramCode();
    //如果result为空,代表读卡插件未启动
    if (result == "") {
      alert("未启动读卡插件!")
    } else {
      //result页面回显
      document.getElementById("ACardResult").value = result;
    }
  } catch (e) {
  }
}

//定时器函数
function testRead() {
  //开始时间
  var startDt = new Date();
  //调用对应的读卡方法
  var result = CertCtl.readCert();
  //如果result为空,代表读卡插件未启动
  if (result == "") {
    alert("未启动读卡插件!")
  } else {
    //结束时间
    var endDt = new Date();
    //读卡时间回显
    document.getElementById("timeElapsed").value = (endDt.getTime() - startDt.getTime()) + "毫秒";
    document.getElementById("result").value = result;
    //var resultObj = toJson(result);
    //result = result.replace("\"resultFlag\":","\"resultFlag\":\"true\"");
    //格式化result
    //var resultObj = $.parseJSON(result);//windows10上面无法解析
    var resultObj = eval('(' + result + ')');
    //resultFlag为0代表读卡成功
    if (resultObj.resultFlag == "0") {
      //清空页面
      //clearForm();
      //回显相关数据
      document.getElementById("signature").value = resultObj.signature;
      document.getElementById("venderId").value = resultObj.venderId;
      document.getElementById("certType").value = resultObj.resultContent.certType;
      document.getElementById("partyName").value = resultObj.resultContent.partyName;
      document.getElementById("gender").value = resultObj.resultContent.gender;
      document.getElementById("nation").value = resultObj.resultContent.nation;
      document.getElementById("bornDay").value = resultObj.resultContent.bornDay;
      document.getElementById("certAddress").value = resultObj.resultContent.certAddress;
      document.getElementById("certNumber").value = resultObj.resultContent.certNumber;
      document.getElementById("certOrg").value = resultObj.resultContent.certOrg;
      document.getElementById("effDate").value = resultObj.resultContent.effDate;
      document.getElementById("expDate").value = resultObj.resultContent.expDate;
      document.getElementById("HeadPic").src = "data:image/jpeg;base64," + resultObj.resultContent.identityPic;
      document.getElementById("PhotoDisplay").src = "data:image/jpeg;base64," + resultObj.resultContent.identityPrintPic;

    } else if (resultObj.resultFlag == "-1") {
      if (resultObj.errorMsg == "端口打开失败") {
        alert("读卡器未连接");
      } else {
        //alert(resultObj.errorMsg);
        //document.getElementById("result").value = resultObj.errorMsg;
      }
    } else if (resultObj.resultFlag == "-2") {
      //alert(resultObj.errorMsg);
      //document.getElementById("result").value = resultObj.errorMsg;
    }
  }

}
function testCycleRead() {
  CertCtl.setRepeatRead();

  timer = setInterval(testRead, 500);
}

function closecycleRead() {
  clearInterval(timer);
}