
if (!window.WebSocket) {
  alert("该版本浏览器不支持WebSocket");
}

var websocket;

export function StartWebSocket(callBack) {
  websocket = new WebSocket("ws://127.0.0.1:9000/");
  websocket.onopen = function (evt) {
      //onOpen(evt) 
      // websocket.send("{\"Method\":\"SdtReadCard\", \"CameraName\":\"1-uni\"}");
      // document.getElementById("retinfo").value = "建立WebSocket连接成功";
      // return true
      callBack('神思')
  };
  websocket.onclose = function (evt) {
      //onClose(evt) 
      // console.log('close');
      callBack('失败2')
  };

  websocket.onerror = function (evt) {
      //onError(evt) 
      console.log(evt);
      callBack('失败2')
      // return false
      // document.getElementById("retinfo").value = "建立WebSocket连接失败";
  };

}

export function OpenDevice() {
  websocket.send("{\"Method\":\"OpenDevice\",\"PortType\":\"AUTO\",\"PortPara\":\"\",\"ExtendPara\":\"\"}");
}

function SdtSamGetIdStr(){
  websocket.send("{\"Method\":\"SdtSamGetIdStr\"}");
} 
function SsseReadCard() {
  var SsseReadCardjson = { "Method": "SsseReadCard", "iType": "xxxx" }
  SsseReadCardjson.iType = 4;


  var params = JSON.stringify(SsseReadCardjson);
  console.log(params);
  //websocket.send("{\"Method\":\"IdReadCard\",\"CardType\":\"16\",\"InfoEncoding\":\"1\",\"TimeOutMs\":\"0\"}");
  websocket.send(params);
}
export function IdReadCard(callBack) {
  OpenDevice()
  SdtSamGetIdStr()
  var IdReadCardjson = { "Method": "IdReadCard", "CardType": "0x10", "InfoEncoding": "0x02", "TimeOutMs": "xxxx" }
  IdReadCardjson.CardType = 16;
  IdReadCardjson.InfoEncoding = 1;
  IdReadCardjson.TimeOutMs = 0;

  var params = JSON.stringify(IdReadCardjson);
  console.log(params);
  //websocket.send("{\"Method\":\"IdReadCard\",\"CardType\":\"16\",\"InfoEncoding\":\"1\",\"TimeOutMs\":\"0\"}");
  websocket.send(params);
  var result = {
    name: '',
    idNumber: '',
    effDate: '',
    expDate: '',
    readerCode: '',
  }
  var _method = []
  websocket.onmessage = function (evt) {
    //onMessage(evt) 
    var data, jsonsr
    
    var jsonsr = JSON.parse(evt.data);
    // document.getElementById("retinfo").value = evt.data;
    // console.log(jsonsr,'jsonsr');
    
    _method.push(jsonsr.Method)
    if(jsonsr.Method == 'SdtSamGetIdStr') {
      if(JSON.parse(evt.data).SamIdStr.split('-')[0] == '') {
        websocket.close()
      }
      // console.log(JSON.parse(evt.data).SamIdStr.split('-'), JSON.parse(evt.data).SamIdStr.split('-').slice(0,3).join(''));
      result.readerCode = JSON.parse(evt.data).SamIdStr.split('-').slice(0,3).join('-')
    }
    if(jsonsr.Method == 'IdReadCard') {
      if(jsonsr.IdCardInfo.split(":").length == 0) {
        websocket.close()
      }
      data = jsonsr.IdCardInfo.split(":")
      result.name = data[1]
      result.idNumber = data[9]
      result.effDate = data[11]
      result.expDate = data[12]
    }
    // 信息全了在调callBack
    let _empty = Object.values(result).filter(t => {
      return t == '' || t == undefined
    })
    if(_empty.length == 0) callBack(result)
    // 调用三次 信息还不全 就返回空信息
    if(_method.length == 3 && _empty.length != 0) {
      callBack(result)
    }
    
    
    //websocket.close();
};
}

function CloseDevice() {
  websocket.send("{\"Method\":\"CloseDevice\"}");
}
