<template>
  <div :id="'myImage' + index" class="card-img">
    <div class="upload-file" >
      <el-dialog
        class="my-imgcard-dialog dialogClass-stop"
        :visible.sync="isShowUploadAll"
        width="1200px"
        :append-to-body="true"
       
      >
        <template slot="title">
          <div
            style="
              margin-top: 25px;
              text-align: center;
              font-size: 20px;
              font-weight: 700;
              margin-bottom: 5px;
            "
          >
            附件上传
          </div>
        </template>
        <!-- <div class="warp" v-if="isShowLoading">
          <div class="warp-box">
              <ProgressBar :value="proValue" :content="'上传中'" ></ProgressBar>
          </div>
            
        </div> -->
        <div>
          <div>
            <el-table
              class="my-audit-table"
              :data="fileList"
              fit
              height="550"
              border
              highlight-current-row
              stripe
            >
              <el-table-column label="序号" align="center" min-width="3%">
                <template slot-scope="{ $index }">{{ $index + 1 }}</template>
              </el-table-column>
              <el-table-column
                label="材料名称"
                min-width="20%"
                align="center"
             
              >
                <template slot-scope="{ row }">
                  <span>
                    {{ row.name }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="大小"
                min-width="8%"
                align="center"
             
              >
                <template slot-scope="{ row }">
                  <span>
                    {{ row.size | filterSize }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="格式"
                min-width="8%"
                align="center"
             
              >
                <template slot-scope="{ row }">
                  <span>
                    {{ row.raw.type }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                min-width="16%"
                class-name="small-padding fixed-width"
              >
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    class="btn"
                 
                    @click="handleView(scope.row)"
                    >查看
                  </el-button>

                  <el-button
                    size="mini" 
                    class="btn btn1"
                   
                    @click="handleRemove(scope.row)"
                    >删除
                  </el-button>
                  <el-button
                    icon="el-icon-caret-top"
                    v-if="scope.$index != 0"
                    @click="handleUp(scope.$index)"
                    circle
                  ></el-button>
                  <el-button
                    icon="el-icon-caret-bottom"
                    v-if="scope.$index != fileList.length - 1"
                    @click="handleDown(scope.$index)"
                    circle
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="upload-file-new">
            <el-upload
              style="padding: 20px; float: right;margin-right: 43%;"
              ref="upload"
              multiple
              action=""
              :on-preview="handleView"
              :on-change="handleChange"
              :on-exceed="handleExceed"
              :file-list="fileList"
              list-type="picture"
              :http-request="uploadFile"
              :auto-upload="false"
              :accept="accept"
            >
              <el-button v-if="fileList.length == 0" slot="trigger" icon="el-icon-plus" type="success"
                >添加文件</el-button
              >
            </el-upload>
            <!-- <div style="position: absolute; bottom: 0; right: 15px;">按住【Shift】可多选</div>
            <div style="padding: 20px">
              <el-button
                slot="trigger"
                @click="handleDelete()"
                icon="el-icon-delete"
                type="danger"
                >清除所有</el-button
              >
            </div> -->
          </div>

          <div class="btn-upload-new"  >
            <el-button
              v-if="fileList.length != 0"
              @click="submitUpload()"
              type="primary"
              style="width:120px;margin-left:46%;"
              icon="el-icon-upload"
              >上传</el-button
            >
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title="图片预览"
        :visible.sync="showFileImg"
        :append-to-body="true"
        width="50%"
        :modal="false"
      >
        <div style="padding: 15px;height:600px;">
          <iframe
          :src="'pdf/web/viewer.html?file=' + dialogImageUrl"
          frameborder="0"
          style="width: 100%; height: 100%"
        ></iframe>
        </div>
      </el-dialog>
      
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { uploadFileAll } from "@/api/formulaHomeApi/index";
export default {
  name: "UploadImgCards",
  props: {
    index: {
      type: String,
      default: "1",
    },
    ocrData: {
      type: Object,
      default: {},
    },
    isOcr: {
      type: Boolean,
      default: true,
    },
    accept: {
      type:String,
      default:'.png, .PNG, .jpg, .JPG,'
    },
  },
  components: {
    //  ProgressBar
  },
  data() {
    return {
      proValue:10,
      num:0,
      isShowUploadAll: false,
      fileData: "", // 文件上传数据（多文件合一）
      fileList: [], // upload多文件数组
      showFileImg: false,
      dialogImageUrl: "",
      isShowLoading:false,
      timr:null,
      replace:""
    };
  },
  filters: {
    filterSize(size) {
      if (!size) return "";
      if (size < Math.pow(1024, 1)) return size + " B";
      if (size < Math.pow(1024, 2))
        return (size / Math.pow(1024, 1)).toFixed(2) + " KB";
      if (size < Math.pow(1024, 3))
        return (size / Math.pow(1024, 2)).toFixed(2) + " MB";
      if (size < Math.pow(1024, 4))
        return (size / Math.pow(1024, 3)).toFixed(2) + " GB";
      return (size / Math.pow(1024, 4)).toFixed(2) + " TB";
    },
  },
  created() {
   
  },
  watch: {
   
  },
  methods: {
    //  弹窗
    handleUploadAll() {
      console.log(this.ocrData.qyzxzjzy);
      this.fileList = []
      this.isShowUploadAll = true;
    },
    // 查看单独图片
    handleView(file) {
      this.dialogImageUrl = file.url;
      this.showFileImg = true;
    },
    /**
     * 描述：图片移除
     * @param file 文件对象
     */
    handleRemove(file) {
      this.$alert("确定删除材料", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            this.doRemove(file);
          }
        },
      });
    },
    /**
     * 描述：图片移除接口
     * @param file 图片文件
     * @returns 移除的图片文件，所剩文件列表
     */
    doRemove(file) {
      this.fileList = this.fileList.filter((item) => {
        return item.uid !== file.uid;
      });
      this.$message({
        message: "删除文件成功！",
        type: "success",
      });
    },
    handleDelete() {
      this.$alert("确定删除所有材料", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            this.fileList = [];
          }
        },
      });
    },
    // 上传文件
    uploadFile(file) {
      this.fileData.append("file", file.file); // append增加数据
    },
    // 上传到服务器
    submitUpload() {
      if (this.fileList.length === 0) {
        this.$message({
          message: "请先选择文件",
          type: "warning",
        });
      } else {
        const isLt100M = this.fileList.every(
          (file) => file.size / 1024 / 1024 < 50
        );
        console.log(isLt100M);
        // return
        if (!isLt100M) {
          this.$message.error("请检查，上传文件大小不能超过50MB!");
        } else {
          this.replace = 10
          this.num = 0
          this.fileData = new FormData(); // new formData对象
          this.$refs.upload.submit(); // 提交调用uploadFile函数
          this.isShowLoading = true
          uploadFileAll(this.fileData).then((res) => {
            this.proValue = 100
            if(res.code == 200){
              setTimeout(() => {
                 this.isShowLoading = false
                 this.isShowUploadAll = false
                 this.$emit("successUpload", res);
              }, 500); 
            }
          });
        }
      }
    },
    // 向上
    handleUp(index) {
      if (index != 0) {
        this.fileList[index] = this.fileList.splice(
          index - 1,
          1,
          this.fileList[index]
        )[0];
      } else {
        this.fileList.push(this.fileList.shift());
      }
    },
    // 向下
    handleDown(index) {
      if (index != this.fileList.length - 1) {
        this.fileList[index] = this.fileList.splice(
          index + 1,
          1,
          this.fileList[index]
        )[0];
      } else {
        this.fileList.unshift(this.fileList.splice(index, 1)[0]);
      }
    },
    // 选取文件超过数量提示
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    //监控上传文件列表
    handleChange(file, fileList, po) {
      let existFile = fileList.find((f) => file.name.indexOf('pdf') == -1);
      console.log('existFileexistFile',existFile);
      if (existFile) {
        this.$message.error("请上传pdf格式文件！");
        fileList.pop();
      }
      this.fileList = fileList;
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
<style scoped>
.upload-file-new{
    position: relative;
}
.upload-file-new >>> .el-upload-list {
  display: none;
}
.btn >>> .el-icon-view::before {
  display: none;
}
.btn {
  color: #295fcc !important;
}
.btn >>> .el-icon-view {
  background-size: 100%;
  width: 15px;
  height: 15px;
}
.btn >>> .el-icon-s-release::before {
  display: none;
}
.btn1 {
  color: #ff402a !important;
}
.btn1 >>> .el-icon-s-release {
  background-size: 100%;
  width: 15px;
  height: 15px;
}
.btn-upload-new {
  /* text-align: center; */
  /* margin-top: 50px; */
  width: 100%;
  margin: 50px auto;
  margin-bottom: 0px;
}

.warp{
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0,0,0,0.3);
   z-index: 2001;
}
.warp-box{
    margin: 34% auto;
    width: 80%;
}
.dialogClass-stop{
  top: -50px;
}
</style>
