<template>
  <div id="cer-module-dialog">
    <el-dialog
      class="my-imgcard-dialog"
      :visible.sync="dialogVisible"
      width="35%"
      :append-to-body="true"
      center
    >
      <template slot="title">
        <div class="tital-header">CA验证</div>
      </template>
      <div style="width: 100%; height: 300px" v-loading="isLoading">
        <el-descriptions class="margin-top" :column="1" size="medium" border>
          <el-descriptions-item :label-style="labelStyle">
            <template slot="label">
              <i class="el-icon-s-custom"></i>
              证 书 名
            </template>
            {{ caInfo.zsm }}
          </el-descriptions-item>
          <el-descriptions-item :label-style="labelStyle">
            <template slot="label">
              <i class="el-icon-cpu"></i>
              序 列 号
            </template>
            {{ caInfo.zsh }}
          </el-descriptions-item>
          <el-descriptions-item :label-style="labelStyle">
            <template slot="label">
              <i class="el-icon-date"></i>
              有 效 期
            </template>
            <span>{{ caInfo.zzyxq }}</span>
            <span>{{ caInfo.dateOutFlag }}</span>
            <span>{{ caInfo.formText }}</span>
          </el-descriptions-item>
          <el-descriptions-item :label-style="labelStyle">
            <template slot="label">
              <i class="el-icon-s-finance"></i>
              颁发机构
            </template>
            {{ caInfo.bfjg }}
          </el-descriptions-item>
          <el-descriptions-item
            :label-style="labelStyle"
            :content-style="{ height: '45px' }"
          >
            <template slot="label">
              <i class="el-icon-s-finance"></i>
              登录密码
            </template>
            <el-input
              id="password"
              v-model="caPassword"
              size="mini"
              show-password
              style="width: 170px; margin-right: 20px"
              placeholder="请输入Ukey登录密码"
            ></el-input>
            <el-button
              type="primary"
              @click="handleCACheck()"
              style="padding: 5px 15px"
              >点击验证</el-button
            >
            <el-button @click="dialogVisible = false" style="padding: 5px 15px"
              >取 消</el-button
            >
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      labelStyle: { "text-align": "center", width: "110px", height: "49px" },
      caInfo: {
        zsm: "",
        zsh: "",
        bfjg: "",
        deviceNum: "",
        zzyxq: "",
        dateOutFlag: "",
        formText: "",
      },
      isLoading: false,
      caPassword: null,
      isView:""
    };
  },
  methods: {
    handleCaInfo(view) {
      this.isView = view
      this.dialogVisible = true;
      this.handleCAInfo();
    },
    //CA登录信息
    handleCAInfo() {
      this.isLoading = false;
      this.isLoading = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.doInitializing();
        }, 200);
      });
    },
    // 重置
    doReset() {
      this.caInfo = {
        zsm: "",
        zsh: "",
        bfjg: "",
        deviceNum: "",
        zzyxq: "",
        dateOutFlag: "",
      };
    },
    /**
     * 初始化CA锁控件
     */
    doInitializing() {
      this.doReset();
      let ca = this.$CAService.initCaObj();
      if (ca == undefined) return;
      const { orgname, signCertSN, bfjg, deviceNum } = ca;
      this.caInfo = {
        zsm: orgname, // 使用者
        zsh: signCertSN, // 签名证书序列号
        bfjg, // 颁发机构
        deviceNum, // 锁号
        zzyxq: "", // 有效期
        dateOutFlag: "",
        formText: "",
      };
      // 处理时间
      this.handSolveDate(ca);
      this.caPassword = null;
      this.isLoading = false;
    },
    handSolveDate({ yxq }) {
      let yxrq = yxq; // 有效期
      // 有效期处理，保留到天，把/替换成-
      yxrq = yxrq.split(" ")[0].replace(new RegExp("/", "gm"), "-");
      yxrq = yxrq.replace(/-/g, "/");
      var myDate = new Date();
      yxrq = new Date(yxrq);
      var days = yxrq.getTime() - myDate.getTime();
      var time = parseInt(days / (1000 * 60 * 60 * 24));
      var d = new Date(yxrq);
      var datetime = `有效期至${d.getFullYear()}年${
        d.getMonth() + 1
      }月${d.getDate()}日`;
      // 有效期处理
      if (yxrq.toString().split("-").length > 0) {
        var Diff = time;
        // 如果证书有效期小于90天将弹出提示信息
        if (Diff < 0) {
          this.caInfo.dateOutFlag = "此证书已经过期，";
          this.caInfo.formText = "为保障业务正常开展，请及时办理延期手续！";
          this.caInfo.zzyxq = datetime;
        } else if (Diff < 90) {
          this.caInfo.formText = "距过期还有" + Diff + "天，请及时办理延期！";
          this.caInfo.zzyxq = datetime;
        } else {
          this.caInfo.formText = "距过期还有" + Diff + "天！";
          this.caInfo.zzyxq = datetime;
        }
      }
    },
    handleCACheck() {
      if (!this.caPassword) {
        this.$message.error({
          message: "请输入密码！",
          type: "error",
        });
        return;
      }
      if (!this.$CAService.CheckPin(this.caPassword)) {
        return;
      }
      this.handCerAssign();
    },
    handCerAssign() {
      this.dialogVisible = false;
      this.caInfo.isView = this.isView
      this.$emit("cerAssignData", this.caInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
#cer-module-dialog {
  width: 100%;
}
</style>
<style  scoped>
.my-imgcard-dialog >>> .tital-header {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  background: #fff;
}
</style>